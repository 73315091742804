@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@layer base {
  html {
    @apply text-text-primary;
    @apply font-Roboto;
  }

  p {
    @apply text-base;
  }
}

.react-tel-input .selected-flag:hover, .open {
  background-color: transparent !important;
}

.react-tel-input .selected-flag .arrow {
  display: none;
}
